import React from "react"
import Slide from "react-reveal/Slide"

export default () => {
  return (
    <>
      <div
        className={
          "container mx-auto flex justify-between lg:flex-row flex-col"
        }
      >
        <Slide left>
          <div
            className={
              "bg-primaryLight shadow-lg rounded p-6 mx-6 my-2 flex-1 flex-col flex text-center"
            }
          >
            {/* <div className={"text-light"}>James Butland</div> */}
            <div className={"text-secondary mb-3"}>
              VP Global Banking - Airwallex
            </div>
            <div className={"text-light"}>
              “In Referment we found a partner that UNDERSTANDS our business but
              also has access to a depth of HIGH-QUALITY talent we wouldn’t have
              been able to source ourselves”
            </div>
          </div>
        </Slide>
        <Slide bottom>
          <div
            className={
              "bg-primaryLight shadow-lg rounded p-6 flex-1 mx-6 my-2 flex-col flex text-center"
            }
          >
            {/* <div className={"text-light"}>Paul Thomas</div> */}
            <div className={"text-secondary mb-3"}>
              {" "}
              General Manager - Vermeg
            </div>
            <div className={"text-light"}>
              “We’ve used Referment a number of times over the past 12 months
              and have been consistently impressed with the quality of
              candidates and their preparedness. I’d highly recommend Referment
              to any firm looking for quality candidates”
            </div>
          </div>
        </Slide>
        <Slide right>
          <div
            className={
              "bg-primaryLight shadow-lg p-6 rounded flex-1 mx-6 my-2 flex-col flex text-center"
            }
          >
            {/* <div className={"text-light"}>Toni Jensen</div> */}
            <div className={"text-secondary mb-3"}>
              Group IT Business Manager - Schroders
            </div>
            <div className={"text-light rounded"}>
              “Referment consistently provide excellent candidates for our
              technology and business functions. We’re impressed with their
              research and knowledge of the business. We would highly recommend
              Referment.”
            </div>
          </div>
        </Slide>
      </div>
    </>
  )
}
