import React from "react"

import styles from "./index.module.css"
import ClientSlider from "../clientSlider"
import Reviews from "../reviews"
import Fade from "react-reveal"

export default () => {
  return (
    <div
      className={`container flex justify-between items-center lg:mt-24 mx-auto ${styles.main}`}
    >
      <div className={"w-full"}>
        <h2 className={"heading-center mb-4"}>
          Our clients<span className={"red"}>.</span>
        </h2>
        <ClientSlider />
        <Reviews />
      </div>
    </div>
  )
}
