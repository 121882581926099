import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import "./index.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img from "gatsby-image"

export default () => {
  const settings = {
    centerMode: true,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: false,
    swipeToSlide: false,
    variableWidth: true,
    dots: false,
  }

  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "clients" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fixed(height: 100, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <Slider {...settings}>
        {allFile.edges.map(img => (
          <div className={"p-16"}>
            <Img loading={"eager"} fixed={img.node.childImageSharp?.fixed} />
          </div>
        ))}
      </Slider> */}
      <div className={"clientSlider shadow-sm rounded-md mt-10 mb-8 flex mx-auto"}>
        <div className="slider">
          <div className="slide-track">
            {allFile.edges.map(img => (
              <div className={"slide"}>
                <Img
                  loading={"eager"}
                  fixed={img.node.childImageSharp?.fixed}
                />
              </div>
            ))}
            {allFile.edges.map(img => (
              <div className={"slide"}>
                <Img
                  loading={"eager"}
                  fixed={img.node.childImageSharp?.fixed}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
